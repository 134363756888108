/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Circle,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
  VStack,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { AccountContext } from '../../../providers/AccountsProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AddIcon,
  AddressIcon,
  DepartmentIcon,
} from '../../../constants/IconData';
import FormMultiSelectModal from '../../../components/form/FormMultiSelect';
import SelectFormModal from './SelectFormModal';
import { CREATE_MODE, DEPARTMENT } from '../../../constants/constants';
import { createDepartmentSchema } from '../validations';
import { separateIdNames } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/Routes';
import { replaceRoute } from '../../../utils/common.utils';
import SpecialtySelect from '../components/SpecialtySelect';
import accountsRepository from '../../../repositories/AccountsRepository';

const AddDepartmentForm = ({
  isOpen,
  onClose,
  mode = CREATE_MODE,
  departmentId,
  hospital,
  account,
  departmentData,
}) => {
  const [radioValue] = useState('0');
  const navigate = useNavigate();
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [selectedPrimaryAccountCheckBoxIndex, spacbiState] = useState([]);
  const [selectedCanUploadCheckBoxIndex, setSelectedCanUploadCheckBoxIndex] = useState([]);
  const GRID_SPACING = 5;

  function createNewContact(primary) {
    return {
      first_name: '',
      last_name: '',
      email: '',
      is_primary: primary,
      can_upload: primary,
    };
  }
  const accountsContext = useContext(AccountContext);
  const { departmentDropdown, getDepartmentDropdown } = accountsContext;
  const form = useForm({
    resolver: yupResolver(createDepartmentSchema),
    defaultValues: {
      account_contact: [createNewContact(true)],
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = form;
  const contactField = useFieldArray({
    control,
    name: 'account_contact',
  });

  const scrollContainerRef = useRef(null);

  function scrollToBottom() {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }

  async function submitAddAccount(formData) {
    try {
      if (mode === CREATE_MODE) {
        //add department
        const departmentBody = {
          account_contact: formData.account_contact,
          ehr: separateIdNames(formData.ehr, 'new_ehr_name'),
          insurance: separateIdNames(formData.insurance, 'new_ins_name'),
          hospital: hospital.id,
        };
        if (formData.department_id) {
          departmentBody.department_id = formData.department_id;
        } else {
          departmentBody.name = formData.name;
        }
        const response = await accountsContext.createDepartment(departmentBody);
        if (response?.id) {
          navigate(
            replaceRoute(ROUTES.GET_DEPARTMENT, {
              accountId: account.id,
              hospitalId: hospital.id,
              departmentId: response.id,
            }),
            {
              state: {
                accountName: account.name,
                hospitalName: hospital.name,
                departmentName: response.name,
              },
            }
          );
        }
      } else {
        //update department
        const departmentBody = {
          name: formData.name,
          address: formData.address,
          account_contact: formData.account_contact,
          ehr: separateIdNames(formData.ehr, 'new_ehr_name'),
          insurance: separateIdNames(formData.insurance, 'new_ins_name'),
          hospital: hospital.id,
        };
        const res = await accountsContext.updateDepartment(
          departmentId,
          departmentBody
        );
        if (res) {
          onClose();
        }
      }
    } catch (err) {
      //err
    }
  }

  useEffect(() => {
    if (Object.keys(accountsContext.updatedHospitalList).length) {
      getDepartmentDropdown(hospital.id);
      setValue('address', accountsContext.updatedHospitalList?.address);
      setValue(
        'ehr',
        accountsContext.updatedHospitalList?.ehr?.map(e => e.id)
      );
      setValue(
        'insurance',
        accountsContext.updatedHospitalList?.insurance?.map(e => e.id)
      );
      setValue('account_contact', [createNewContact(true)]);
    }
  }, [accountsContext.updatedHospitalList]);

  useEffect(() => {
    if (hospital.id) {
      getDepartmentDropdown(hospital.id);
      setValue('address', hospital?.address);
      setValue(
        'ehr',
        hospital?.ehr?.map(e => e.id)
      );
      setValue(
        'insurance',
        hospital?.insurance?.map(e => e.id)
      );
      setValue('account_contact', [createNewContact(true)]);
    }
  }, [hospital]);

  useEffect(() => {
    if (hospital.id) {
      getDepartmentDropdown(hospital.id);
      setValue('address', hospital?.address);
      setValue(
        'ehr',
        hospital?.ehr?.map(e => e.id)
      );
      setValue(
        'insurance',
        hospital?.insurance?.map(e => e.id)
      );
      setValue('account_contact', [createNewContact(true)]);
    }
  }, []);

  useEffect(() => {
    if (departmentData.id) {
      reset({
        ...departmentData,
        ehr: departmentData.ehr.length ? departmentData.ehr.map(e => e.id) : [],
        insurance: departmentData.insurance.length
          ? departmentData.insurance.map(e => e.id)
          : [],
        account_contact: departmentData.account_contacts,
      });
    }
  }, [departmentData]);

  useEffect(() => {
    const initialSelectedIndexes = [];
    getValues('account_contact')?.forEach((dt, index) => {
      if (index === 0) {
        initialSelectedIndexes.push(index);
        setValue(`account_contact.${index}.can_upload`, true);
      }
    });
    setSelectedCanUploadCheckBoxIndex(initialSelectedIndexes);
  }, []);

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedPrimaryAccountCheckBoxIndex.includes(index)) {
        setValue(`account_contact.${index}.is_primary`, true);
      } else {
        setValue(`account_contact.${index}.is_primary`, false);
      }
    });
  }, [selectedPrimaryAccountCheckBoxIndex]);

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedCanUploadCheckBoxIndex.includes(index)) {
        setValue(`account_contact.${index}.can_upload`, true);
      } else {
        setValue(`account_contact.${index}.can_upload`, false);
      }
    });
    scrollToBottom();
  }, [selectedCanUploadCheckBoxIndex]);

  useEffect(() => {
    const errorObject = accountsContext.errorObject[DEPARTMENT]
      ? accountsContext.errorObject[DEPARTMENT]
      : {};
    if (errorObject && Object.keys(errorObject).length) {
      const errorKeys = Object.keys(errorObject);
      errorKeys.map(key => {
        const contactError = errorObject[key];
        if (typeof contactError === 'object') {
          if (typeof contactError === 'object') {
            if (contactError.first_name) {
              setError(`account_contact.${key}.first_name`, {
                type: 'custom',
                message: contactError.first_name,
              });
            }
            if (contactError.last_name) {
              setError(`account_contact.${key}.last_name`, {
                type: 'custom',
                message: contactError.last_name,
              });
            }
            if (contactError.email) {
              setError(`account_contact.${key}.email`, {
                type: 'custom',
                message: contactError.email,
              });
            }
          }
        } else {
          setError(key, { type: 'custom', message: errorObject[key] });
        }
      });
    }
  }, [accountsContext.errorObject]);

  useEffect(() => {
    if (selectedDepartment && Object.keys(selectedDepartment).length) {
      setValue('name', selectedDepartment.label);
      if (selectedDepartment.id) {
        setValue('department_id', selectedDepartment.value);
      }
    }
  }, [selectedDepartment]);

  const handleCheckBox = (event, index) => {
    let checkedIndexes = [...selectedPrimaryAccountCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index);
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index);
    }
    spacbiState(checkedIndexes);
  };

  const handleCheckBoxUpload = (event, index) => {
    let checkedIndexes = [...selectedCanUploadCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index);
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index);
    }
    setSelectedCanUploadCheckBoxIndex(checkedIndexes);
  };

  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <VStack display={'block'}>
          <SimpleGrid
            columns={{ sm: 1, md: 4 }}
            spacing={GRID_SPACING}
            alignItems={'baseline'}
          >
            <FormTextInput
              name={`account_contact.${index}.first_name`}
              control={control}
              height={'50px'}
              placeholder={'First Name'}
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.last_name`}
              control={control}
              height={'50px'}
              placeholder={'Last Name'}
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.email`}
              control={control}
              height={'50px'}
              placeholder={'Email'}
              size="md"
            />

            {index.toString() !== radioValue ? (
              <Text
                onClick={() => contactField.remove(index)}
                cursor={'pointer'}
                color={AppColors.secondary}
                fontSize="sm"
              >
                Remove
              </Text>
            ) : null}
          </SimpleGrid>
          <Flex align="center" mb={3} mt={2}>
            <Checkbox
              onChange={e => handleCheckBox(e, index)}
              value={index.toString()}
            >
              Primary Account
            </Checkbox>
            <Checkbox
              onChange={e => handleCheckBoxUpload(e, index)}
              value={index.toString()}
              defaultChecked={selectedCanUploadCheckBoxIndex.includes(index)}
              ml={5}
            >
              Can Upload
            </Checkbox>
          </Flex>
        </VStack>
      </React.Fragment>
    );
  }

  function renderAddIcon(_props) {
    return (
      <HStack mt={3} mr={12} mb={3} _hover={{ cursor: 'pointer' }} {..._props}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <AddIcon style={{ width: 18, height: 18 }} />
      </HStack>
    );
  }

  function renderContacts() {
    return (
      <SimpleGrid spacing={GRID_SPACING}>
        {contactField.fields.map(renderAddContactForm)}
      </SimpleGrid>
    );
  }

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }

  function renderInsuranceForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Insurance'}
        addNewText={'Other Insurance Name'}
        subTitle={'Select Insurance'}
        isFetching={accountsContext.isFetchingInsurance}
        isCreating={accountsContext.isCreatingInsurance}
        onCreateNewOption={accountsContext.createInsuranceOption}
      />
    );
  }

  const promiseOptions = async inputValue => {
    return accountsRepository.getSpecialtyOptions({
      search: inputValue,
      hospital_id: hospital.id,
    });
  };

  const onModalClose = () => {
    onClose();
    reset({
      name: '',
      // address: '',
      account_contact: [createNewContact()],
    });
  };
  return (
    <React.Fragment>
      <Modal
        size={'4xl'}
        isOpen={isOpen}
        onClose={onModalClose}
        isCentered
      // blockScrollOnMount={blockScrollOnMount ?? true}
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        <ModalContent mx={4} px={8}>
          <ModalHeader
            fontFamily={FONT_FAMILY.baiJamurjee}
            textAlign={'center'}
          >
            {mode === CREATE_MODE ? 'Add' : 'Edit'} Department
          </ModalHeader>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-10}
            right={-3}
          >
            <Circle>
              <ModalCloseButton
                size={'sm'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={onModalClose}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalBody pb={6}>
            <SimpleGrid
              columns={{ sm: 1, md: 2, lg: 2 }}
              spacing={GRID_SPACING}
              mb={GRID_SPACING}
            >
              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'} color={'black'}>
                  Department Name
                </FormLabel>

                {mode === CREATE_MODE ? (
                  <>
                    <SpecialtySelect
                      dataValue={selectedDepartment}
                      setDataValue={setSelectedDepartment}
                      promiseOptions={promiseOptions}
                      options={departmentDropdown}
                      placeholder="Search and Select dept. name"
                    />
                    {errors.name ? (
                      <Text color={'red'}>{errors['name']['message']}</Text>
                    ) : null}
                  </>
                ) : (
                  <FormTextInput
                    name="name"
                    control={control}
                    placeholder={'Department Name'}
                    leftIcon={
                      <DepartmentIcon style={{ height: 16, width: 16 }} />
                    }
                    size="md"
                  />
                )}
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'} color="black">
                  Hospital State
                </FormLabel>
                <FormTextInput
                  name="address"
                  control={control}
                  disabled={mode === CREATE_MODE}
                  placeholder={'State'}
                  leftIcon={<AddressIcon style={{ width: 16, height: 16 }} />}
                  size="md"
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'} color="black">
                  Hospital Insurance
                </FormLabel>
                <FormMultiSelectModal
                  control={control}
                  name={'insurance'}
                  placeholder={'None'}
                  options={accountsContext.insuranceOptions}
                  renderFormModal={renderInsuranceForModal}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                  Hospital EHR
                </FormLabel>
                <FormMultiSelectModal
                  control={control}
                  name={'ehr'}
                  placeholder={'None'}
                  options={accountsContext.ehrOptions}
                  renderFormModal={renderEHRFormModal}
                />
              </FormControl>
            </SimpleGrid>

            <FormControl>
              <FormLabel fontSize={'xs'} fontWeight={'bold'} htmlFor="add-dept-account-form">
                Account Contact
              </FormLabel>
              <Box>
                <Box
                  maxHeight={190}
                  overflow="scroll"
                  overflowX={'hidden'}
                  ref={scrollContainerRef}
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 4px lightGrey',
                      width: '4px',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'grey',
                      borderRadius: '4px',
                    },
                  }}
                >
                  {renderContacts()}
                </Box>
                <Box>
                  {renderAddIcon({
                    onClick: () => {
                      const currentContacts = getValues("account_contact");
                      if (contactField.fields.length === 1) {
                        const updatedContacts = [currentContacts[0], createNewContact()];
                        reset({
                          ...getValues(),
                          account_contact: updatedContacts,
                        });
                      } else {
                        contactField.append(createNewContact());
                      }
                      setSelectedCanUploadCheckBoxIndex(prev => [...prev, contactField.fields.length]);
                    },
                  })}
                </Box>
              </Box>
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent={'center'}>
            <Button
              mr={3}
              borderRadius={'full'}
              px={12}
              height={'50px'}
              borderWidth={2}
              borderColor={AppColors.secondary}
              onClick={onModalClose}
              variant={'outline'}
            >
              <Text fontWeight={'normal'} fontSize={'sm'}>
                Cancel
              </Text>
            </Button>
            <Button
              bgColor={AppColors.primary}
              borderRadius={'full'}
              height={'50px'}
              px={12}
              isLoading={accountsContext.isDepartmentLoading}
              onClick={handleSubmit(submitAddAccount)}
            >
              <Text color={'white'} fontSize={'sm'}>
                {mode === CREATE_MODE ? 'Add' : 'Update'}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
export default AddDepartmentForm;
