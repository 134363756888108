import {
  Box,
  Button,
  Circle,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import {
  FormTextInput,
  useCQMediaQuery,
  useToastr,
} from '@laxmimanogna/code-quick-components';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { AccountContext } from '../../../providers/AccountsProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { CREATE_MODE, onlyAlphabetsRegex } from '../../../constants/constants';
import ReactSelect from 'react-select/creatable';
import * as yup from 'yup';

const AddPhysicianForm = ({
  isOpen,
  onClose,
  mode = CREATE_MODE,
  setListProviderData,
  departmentId,
  listProviderData,
}) => {
  const [emailOptions, setEmailOptions] = useState([]);
  const [enteredEmailInput, setEnteredEmailInput] = useState({});
  const [isEmailNew, setIsEmailNew] = useState(false);
  const [createMoodleAccount, setCreateMoodleAccount] = useState(false);
  const [selectedCanUploadCheckBox, setSelectedCanUploadCheckBox] = useState(false);
  const toast = useToastr();

  const GRID_SPACING = 5;

  const accountsContext = useContext(AccountContext);

  const getEmailByKeyword = data => {
    if (data.length !== 0) {
      const getEmailFromDb = accountsContext.getProviderEmailByKeyword(data);
      getEmailFromDb.then(res => {
        setEmailOptions(res);
      });
    }
  };

  const blockScrollOnMount = useCQMediaQuery({
    sm: false,
  });

  const physicianSchema = yup.object().shape({
    first_name: yup
      .string()
      .matches(onlyAlphabetsRegex, 'First Name must be alphabetic.')
      .nullable(),
    last_name: yup
      .string()
      .matches(onlyAlphabetsRegex, 'Last Name must be alphabetic.')
      .nullable(),
  });

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(physicianSchema),
  });

  const isEmailExistFunction = () => {
    if (emailOptions.length === 0) setIsEmailNew(true);
  };

  async function submitAddAccount(formData) {
    var providerBody = {};

    // if (formData?.first_name) {
    //   formData?.first_name.ma;
    //   setError('first_name', {
    //     type: 'manual',
    //     message: '',
    //   });
    // }

    if (emailOptions.length === 0)
      providerBody = { ...formData, email: enteredEmailInput?.value };
    else providerBody.provider_id = enteredEmailInput.id;

    providerBody = { ...providerBody, can_upload: selectedCanUploadCheckBox };

    const isEmailExist = await listProviderData?.find(
      lp => lp?.email === enteredEmailInput?.value
    );
    if (isEmailExist) {
      toast.showError({
        description: `${isEmailExist?.email} Email Exist in Department`,
      });
    } else {
      try {
        const res = await accountsContext.createProvider(
          departmentId,
          providerBody
        );
        if (createMoodleAccount) {
          const moodleStatus = await accountsContext.moodleAccountCreate(
            providerBody
          );
        }
        if (res) {
          if (listProviderData?.length && res) {
            setListProviderData(prev => [...prev, res] || []);
          } else if (res) {
            setListProviderData([res]);
          }
          modalOnClose();
        }
      } catch (error) {
        toast.showError({
          description: `Error occurred: ${error.message}`,
        });
      }
    }
  }   

  function modalOnClose() {
    reset({
      first_name: '',
      last_name: '',
    });
    onClose();
  }

  return (
    <React.Fragment>
      <Modal
        size={'4xl'}
        isOpen={isOpen}
        onClose={modalOnClose}
        isCentered
        blockScrollOnMount={blockScrollOnMount ?? true}
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        <ModalContent mx={4} px={8}>
          <ModalHeader
            fontFamily={FONT_FAMILY.baiJamurjee}
            textAlign={'center'}
          >
            {mode === CREATE_MODE ? 'Add' : 'Edit'} Provider
          </ModalHeader>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-10}
            right={-3}
          >
            <Circle>
              <ModalCloseButton
                size={'sm'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={onClose}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <Box>
            <form onSubmit={handleSubmit(submitAddAccount)}>
              <ModalBody pb={6}>
                <SimpleGrid
                  columns={{ sm: 1, md: 2, lg: 2 }}
                  spacing={GRID_SPACING}
                  mb={GRID_SPACING}
                >
                  <FormControl isInvalid={errors.email}>
                    <FormLabel
                      fontSize={'xs'}
                      fontWeight={'bold'}
                      color="black"
                    >
                      Email
                    </FormLabel>
                    <ReactSelect
                      name="email"
                      onChange={e => {
                        isEmailExistFunction();
                        setEnteredEmailInput(e);
                      }}
                      formatCreateLabel={inputValue => {
                        return (
                          <div style={{ fontSize: '12px' }}>
                            Add{' '}
                            <span
                              style={{ fontSize: '16px', fontWeight: 'bold' }}
                            >
                              {inputValue}
                            </span>{' '}
                            to RevIntegrity
                          </div>
                        );
                      }}
                      placeholder="Type your Email"
                      onInputChange={e => getEmailByKeyword(e)}
                      styles={{ control: styles => ({ ...styles, height: 50 }) }}
                      isClearable={true}
                      control={control}
                      options={emailOptions?.map(e => {
                        return { id: e.id, value: e.email, label: e.email };
                      })}
                    />
                  </FormControl>

                  <br />
                  <FormControl display={!isEmailNew && 'none'} isInvalid={errors.first_name}>
                    <FormLabel
                      fontSize={'xs'}
                      fontWeight={'bold'}
                      color={'black'}
                    >
                      First Name
                    </FormLabel>
                    <FormTextInput
                      name="first_name"
                      control={control}
                      placeholder={'First Name'}
                      size="md"
                    />
                  </FormControl>

                  <FormControl display={!isEmailNew && 'none'} isInvalid={errors.last_name}>
                    <FormLabel
                      fontSize={'xs'}
                      fontWeight={'bold'}
                      color="black"
                    >
                      Last Name
                    </FormLabel>
                    <FormTextInput
                      name="last_name"
                      control={control}
                      placeholder={'Last Name'}
                      size="md"
                    />
                  </FormControl>
                </SimpleGrid>
                <Flex align="center" mt={GRID_SPACING}>
                  <Checkbox
                    isChecked={selectedCanUploadCheckBox}
                    onChange={e => setSelectedCanUploadCheckBox(e.target.checked)}
                  >
                    Can Upload
                  </Checkbox>
                  <Checkbox
                    ml={5}
                    isChecked={createMoodleAccount}
                    onChange={e => setCreateMoodleAccount(e.target.checked)}
                  >
                    Create Moodle Account
                  </Checkbox>
                </Flex>
              </ModalBody>
              <ModalFooter justifyContent={'center'}>
                <Button
                  mr={3}
                  borderRadius={'full'}
                  px={12}
                  borderWidth={2}
                  borderColor={AppColors.secondary}
                  onClick={modalOnClose}
                  variant={'outline'}
                >
                  <Text fontWeight={'normal'} fontSize={'sm'}>
                    Cancel
                  </Text>
                </Button>
                <Button
                  bgColor={AppColors.primary}
                  borderRadius={'full'}
                  px={12}
                  type={'submit'}
                  isLoading={accountsContext.isProviderCreating || accountsContext.isMoodleAccountCreating}
                  loadingText={'Creating'}
                  spinnerPlacement={'end'}
                >
                  <Text color={'white'} fontSize={'sm'}>
                    {mode === CREATE_MODE ? 'Add' : 'Update'}
                  </Text>
                </Button>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
export default AddPhysicianForm;
