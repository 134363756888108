/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Circle,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
  Spinner,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import {
  FormCQSelect,
  FormTextInput,
  useCQMediaQuery,
} from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AppColors from '../../../constants/AppColors';
import {
  AddIcon,
  HealthSystemIcon,
  PersonIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SelectFormModal from './SelectFormModal';
import FormMultiSelectModal from '../../../components/form/FormMultiSelect';
import { AccountContext } from '../../../providers/AccountsProvider';
import { separateIdNames } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { replaceRoute } from '../../../utils/common.utils';
import ROUTES from '../../../constants/Routes';
import { EditAccountContext } from '../../../providers/EditAccountProvider';
import accountsRepository from '../../../repositories/AccountsRepository';
import { STATES, headingValidationRegex, onlyAlphabetsRegex } from '../../../constants/constants';
import SearchDropDown from '../components/SearchDropDown';

const AddHospitalScheme = yup.object({
  name: yup
    .string()
    .required('Name is a required field')
    .matches(headingValidationRegex, 'Name must be valid'),
  address: yup.string(),
  patients_per_month: yup
    .string()
    .matches(/^\d+$/, 'Only Numbers allowed ')
    .required('Patients per month is a required field'),
  account_contact: yup.array().of(
    yup.object().shape({
      first_name: yup
        .string()
        .required('First name is required')
        .matches(onlyAlphabetsRegex, 'First Name must be valid'),
      last_name: yup
        .string()
        .required('Last name is required')
        .matches(onlyAlphabetsRegex, 'Last Name must be valid'),
      email: yup
        .string().email("Enter valid email")
        .required('Email is required'),
    })
  ),
});

const GRID_SPACING = 5;

function createNewContact() {
  return {
    first_name: '',
    last_name: '',
    email: '',
    is_primary: false,
    can_upload: true,
  };
}

const AddHospitalForm = ({
  isOpen,
  onClose,
  hospitalId,
  editHospitalId,
  accountId,
  healthSystemId,
  hsAddress,
  setHsState,
}) => {
  // context
  const accountsContext = useContext(AccountContext);
  const editAccountContext = useContext(EditAccountContext);
  const [selectedPrimaryAccountCheckBoxIndex, spacbiState] = useState([]);
  const [selectedCanUploadCheckBoxIndex, setSelectedCanUploadCheckBoxIndex] = useState([]);
  const [hospitalState, setHospitalState] = useState('');

  const [dataValue, setDataValue] = useState({});
  const navigate = useNavigate();

  const blockScrollOnMount = useCQMediaQuery({
    sm: false,
  });

  const form = useForm({
    resolver: yupResolver(AddHospitalScheme),
    defaultValues: {
      account_contact: [createNewContact()],
    },
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const contactField = useFieldArray({
    control,
    name: 'account_contact',
  });

  const scrollContainerRef = useRef(null);

  function scrollToBottom() {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    if (editHospitalId) {
      reset({ ...accountsContext.hospitalData });
      accountsContext.fetchHospital(editHospitalId).then(res => {
        setValue(
          'specialties',
          res.specialty.map(s => s.id)
        );
        setValue(
          'ehr',
          res.ehr.map(s => s.id)
        );
        setValue(
          'insurance',
          res.insurance.map(s => s.id)
        );
        setHospitalState(res.address);
        setValue('address', res.address);
      });
    }
  }, [editHospitalId]);

  useEffect(() => {
    if (healthSystemId) {
      editAccountContext.fetchHealthSystem(healthSystemId).then(res => {
        setValue(
          'specialties',
          res.specialty.map(s => s.id)
        );
        setValue(
          'ehr',
          res.ehr.map(s => s.id)
        );
        setValue(
          'insurance',
          res.insurance.map(s => s.id)
        );
        setHospitalState(res.address);
        setValue('address', res.address);
      });
    }
  }, [healthSystemId]);

  useEffect(() => {
    const initialSelectedIndexes = [];
    getValues('account_contact')?.forEach((dt, index) => {
      if (index === 0) {
        initialSelectedIndexes.push(index);
        setValue(`account_contact.${index}.can_upload`, true);
      }
    });
    setSelectedCanUploadCheckBoxIndex(initialSelectedIndexes);
  }, []);

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedPrimaryAccountCheckBoxIndex.includes(index)) {
        setValue(`account_contact.${index}.is_primary`, true);
      } else {
        setValue(`account_contact.${index}.is_primary`, false);
      }
    });
  }, [selectedPrimaryAccountCheckBoxIndex]);

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedCanUploadCheckBoxIndex.includes(index)) {
        setValue(`account_contact.${index}.can_upload`, true);
      } else {
        setValue(`account_contact.${index}.can_upload`, false);
      }
    });
    scrollToBottom();
  }, [selectedCanUploadCheckBoxIndex]);

  useEffect(() => {
    if (dataValue && Object.keys(dataValue).length) {
      if (
        accountsContext.specialtyOptions.findIndex(
          d => d.id === dataValue.id && d.label === dataValue.label
        ) === -1
      ) {
        accountsContext.setSpecialtyOptions(prev => [...prev, dataValue]);
      }
    }
  }, [dataValue]);

  const handleCheckBox = (event, index) => {
    let checkedIndexes = [...selectedPrimaryAccountCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index);
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index);
    }
    spacbiState(checkedIndexes);
  };

  const handleCheckBoxUpload = (event, index) => {
    let checkedIndexes = [...selectedCanUploadCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index);
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index);
    }
    setSelectedCanUploadCheckBoxIndex(checkedIndexes);
  };

  useEffect(() => {
    loadOptions();
    accountsContext.cqHospitalsData();
  }, []);

  /** --- DATA FETCHING --- */
  function loadOptions() {
    accountsContext.fetchEHROptions();
    accountsContext.fetchInsuranceOptions();
    accountsContext.fetchSpecialtyOptions();
  }

  const addHospital = hospitalBody => {
    const isHospitalExist = accountsContext.hospitalDatas?.find(h => {
      if (h?.name === hospitalBody.name) {
        setError('name', {
          type: 'manual',
          message: `${hospitalBody.name} already exists`,
        });
        return h?.name;
      }
    });
    if (!isHospitalExist) {
      accountsContext
        .createHospital({
          ...hospitalBody,
          address: hospitalState?.split(',')?.at(-1)?.trim(),
        })
        .then(res => {
          if (res) {
            const hospitalEditPage = replaceRoute(ROUTES.GET_HOSPITAL, {
              accountId,
              hospitalId: res?.id,
            });
            navigate(hospitalEditPage);
            reset({
              account_contact: [createNewContact()],
            });
            onClose();
          }
        });
    }
  };

  /** --- DATA HANDLING --- */
  async function submitAddAccount(formData) {
    const hospitalBody = {
      name: formData.name,
      address: formData.address?.split(',').at(-1).trim(),
      patients_per_month: formData.patients_per_month,
      specialties: separateIdNames(formData.specialties, 'new_spec_name'),
      insurance: separateIdNames(formData.insurance, 'new_ins_name'),
      ehr: separateIdNames(formData.ehr, 'new_ehr_name'),
      account_contact: formData.account_contact,
      health_system: accountId,
    };

    if (editHospitalId) {
      accountsContext.updateHospital(editHospitalId, hospitalBody).then(res => {
        const hospitalEditPage = replaceRoute(ROUTES.GET_HOSPITAL, {
          accountId,
          hospitalId: res.id,
        });
        navigate(hospitalEditPage);
        onClose();
      });
    } else {
      addHospital(hospitalBody);
    }
  }

  function renderAddIcon(_props) {
    return (
      <HStack mr={12} mb={3} _hover={{ cursor: 'pointer' }} {..._props}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <AddIcon style={{ width: 18, height: 18 }} />
      </HStack>
    );
  }

  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={GRID_SPACING}>
          <FormTextInput
            name={`account_contact.${index}.first_name`}
            height={'50px'}
            control={control}
            placeholder={'First Name'}
            size="md"
          />
          <FormTextInput
            name={`account_contact.${index}.last_name`}
            control={control}
            height={'50px'}
            placeholder={'Last Name'}
            size="md"
          />
          <FormTextInput
            name={`account_contact.${index}.email`}
            control={control}
            height={'50px'}
            placeholder={'Email'}
            size="md"
          />
          {index === 0 && <br />}
          <Text
            onClick={() => contactField.remove(index)}
            cursor={'pointer'}
            color={AppColors.secondary}
            fontSize="sm"
            display={index > 0 ? 'block' : 'none'}
          >
            Remove
          </Text>
        </SimpleGrid>
        <Flex align="center" mb={5} mt={2}>
          <Checkbox
            onChange={e => handleCheckBox(e, index)}
            value={index.toString()}
          >
            Primary Account
          </Checkbox>
          <Checkbox
            onChange={e => handleCheckBoxUpload(e, index)}
            value={index.toString()}
            defaultChecked={selectedCanUploadCheckBoxIndex.includes(index)}
            ml={5}
          >
            Can Upload
          </Checkbox>
        </Flex>
      </React.Fragment>
    );
  }

  function renderContacts() {
    return <Box>{contactField.fields.map(renderAddContactForm)}</Box>;
  }

  const promiseOptions = async inputValue => {
    return accountsRepository.getSpecialtyOptions({ search: inputValue });
  };
  const renderSpecialtySelect = () => {
    return (
      <SearchDropDown
        dataValue={dataValue}
        setDataValue={setDataValue}
        promiseOptions={promiseOptions}
      />
    );
  };
  function renderSpecialtiesForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        addNewText={'Other Specialty Name'}
        subTitle={'Select Account Specialties'}
        isFetching={accountsContext.isFetchingSpecialtyOption}
        isCreating={accountsContext.isCreatingSpecialtyOption}
        onCreateNewOption={accountsContext.createSpecialtyOption}
        renderSpecialtySelect={renderSpecialtySelect}
        dataValue={dataValue}
      />
    );
  }

  function renderInsuranceFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        addNewText={'Add Other Insurance'}
        subTitle={'Select Insurance'}
        isFetching={accountsContext.isFetchingInsurance}
        isCreating={accountsContext.isCreatingInsurance}
        onCreateNewOption={accountsContext.createInsuranceOption}
      />
    );
  }

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }

  const onModalClose = () => {
    onClose();
    reset({
      name: '',
      // address: '',
      patients_per_month: '',
      account_contact: [createNewContact()],
    });
  };

  return (
    <React.Fragment>
      <Modal
        size={'4xl'}
        isOpen={isOpen}
        onClose={onModalClose}
        isCentered
        blockScrollOnMount={blockScrollOnMount ?? true}
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        {accountsContext.isGettingHospitalData ? (
          <ModalContent width={'100'}>
            <Spinner size="xl" />
          </ModalContent>
        ) : (
          <ModalContent mx={4} px={8}>
            <ModalHeader
              fontFamily={FONT_FAMILY.baiJamurjee}
              textAlign={'center'}
            >
              {!hospitalId ? 'Add Hospital' : 'Edit Hospital'}
            </ModalHeader>
            <Box
              style={{
                zIndex: '1',
              }}
              position={'absolute'}
              top={-10}
              right={-3}
            >
              <Circle>
                <ModalCloseButton
                  size={'sm'}
                  style={{ color: AppColors.white }}
                  backgroundColor={'#8894A6'}
                  p={3}
                  onClick={onModalClose}
                  borderRadius={50}
                />
              </Circle>
            </Box>
            <ModalBody pb={6}>
              <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 2 }}
                spacing={GRID_SPACING}
                mb={GRID_SPACING}
              >
                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Hospital Name
                  </FormLabel>
                  <FormTextInput
                    name="name"
                    control={control}
                    height={'50px'}
                    placeholder={'Hospital Name'}
                    leftIcon={
                      <HealthSystemIcon style={{ marginTop: '10px' }} />
                    }
                    size="md"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    State
                  </FormLabel>

                  <FormCQSelect
                    control={control}
                    options={STATES}
                    value={STATES.find(
                      state => state.value === hsAddress?.split(',')[1]?.trim()
                      // hospitalState?.split(',')[1]?.trim()
                    )}
                    onChange={e => {
                      setHospitalState(e.label);
                      setValue('address', e.label);
                      setHsState(e.label);
                    }}
                    styles={{
                      control: styles => ({
                        ...styles,
                        height: '50px',
                        borderRadius: '5px',
                        border: `1px solid rgba(0,0,0,0.1)`,
                        zIndex: 99,
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        zIndex: 9999999,
                      }),
                    }}
                    id="state"
                    name="state"
                    placeholder="Select State"
                    variant="primary"
                    isSearchable={true}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Specialties
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'specialties'}
                    placeholder={'Ex. Cardiology'}
                    options={accountsContext.specialtyOptions}
                    renderFormModal={renderSpecialtiesForModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Insurance
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'insurance'}
                    placeholder={'None'}
                    options={accountsContext.insuranceOptions}
                    renderFormModal={renderInsuranceFormModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    EHR
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'ehr'}
                    placeholder={'None'}
                    options={accountsContext.ehrOptions}
                    renderFormModal={renderEHRFormModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    No. of Patients per month
                  </FormLabel>
                  <FormTextInput
                    name="patients_per_month"
                    control={control}
                    height={'50px'}
                    placeholder={'Type here'}
                    leftIcon={
                      <PersonIcon
                        style={{ width: 16, height: 16, marginTop: '10px' }}
                      />
                    }
                    size="md"
                  />
                </FormControl>
              </SimpleGrid>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'} htmlFor="add-hosp-account-form">
                  Account Contact
                </FormLabel>
                <Box>
                  <Box
                    maxHeight={195}
                    overflow="scroll"
                    overflowX={'hidden'}
                    ref={scrollContainerRef}
                    sx={{
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 4px lightGrey',
                        width: '4px',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'grey',
                        borderRadius: '4px',
                      },
                    }}
                  >
                    {renderContacts()}
                  </Box>
                  <Box>
                    {renderAddIcon({
                      onClick: () => {
                        const currentContacts = getValues("account_contact");
                        if (contactField.fields.length === 1) {
                          const updatedContacts = [currentContacts[0], createNewContact()];
                          reset({
                            ...getValues(),
                            account_contact: updatedContacts,
                          });
                        } else {
                          contactField.append(createNewContact());
                        }
                        setSelectedCanUploadCheckBoxIndex(prev => [...prev, contactField.fields.length]);
                      },
                    })}
                  </Box>
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter justifyContent={'center'}>
              <Button
                mr={3}
                borderRadius={'full'}
                px={12}
                height={'50px'}
                borderWidth={2}
                borderColor={AppColors.secondary}
                onClick={onModalClose}
                variant={'outline'}
              >
                <Text fontWeight={'normal'} fontSize={'sm'}>
                  Cancel
                </Text>
              </Button>
              <Button
                bgColor={AppColors.primary}
                borderRadius={'full'}
                px={12}
                height={'50px'}
                isLoading={accountsContext.isCreatingHospital}
                loadingText={'Creating'}
                spinnerPlacement={'end'}
                onClick={handleSubmit(submitAddAccount)}
              >
                <Text color={'white'} fontSize={'sm'}>
                  {!hospitalId ? 'Add' : 'Update'}
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default AddHospitalForm;
