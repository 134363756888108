/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
  VStack,
  Checkbox,
  FormControl,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import AppColors from '../../../constants/AppColors';
import { AddIcon } from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import * as yup from 'yup';
import { onlyAlphabetsRegex } from '../../../constants/constants';

const EditAccountContact = ({
  isOpen,
  onClose,
  onContactChange,
  accountData,
}) => {
  const accountEditSchema = {
    is_primary: yup.bool().required(),
    can_upload: yup.bool().required(),
    first_name: yup
      .string()
      .required('First Name is required')
      .matches(onlyAlphabetsRegex, 'First Name must be valid'),
    last_name: yup
      .string()
      .required('Last Name is required')
      .matches(onlyAlphabetsRegex, 'Last Name must be valid'),
    email: yup.string().email("Enter valid email").required('Email is required'),
  };

  const [radioValue] = useState('0');
  const createAccountSchema = yup.object({
    account_contact: yup
      .array()
      .of(yup.object(accountEditSchema))
      // .required()
      .min(1),
  });

  const [selectedPrimaryAccountCheckBoxIndex, spacbiState] = useState([]);
  const [selectedCanUploadCheckBoxIndex, setSelectedCanUploadCheckBoxIndex] = useState([]);

  const GRID_SPACING = 5;

  function createNewContact() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      is_primary: false,
      can_upload: true,
    };
  }

  const handleCheckBox = (event, index) => {
    let checkedIndexes = [...selectedPrimaryAccountCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index.toString());
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index.toString());
    }
    spacbiState(checkedIndexes);
  };

  const handleCheckBoxForCanUpload = (event, index) => {
    let checkedIndexes = [...selectedCanUploadCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index.toString());
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index.toString());
    }
    setSelectedCanUploadCheckBoxIndex(checkedIndexes);
  };

  const form = useForm({
    resolver: yupResolver(createAccountSchema),
    mode: 'onSubmit',
    defaultValues: {
      account_contact: !accountData?.length
        ? [createNewContact()]
        : accountData,
    },
  });
  const { control, handleSubmit, setValue, getValues, reset } = form;
  const contactField = useFieldArray({
    control,
    name: 'account_contact',
  });

  const scrollContainerRef = useRef(null);

  function scrollToBottom() {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }

  function submitAddAccount(formData) {
    onContactChange(formData);
  }

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedPrimaryAccountCheckBoxIndex.includes(index.toString())) {
        setValue(`account_contact.${index}.is_primary`, true);
      } else {
        setValue(`account_contact.${index}.is_primary`, false);
      }
    });
  }, [selectedPrimaryAccountCheckBoxIndex]);

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedCanUploadCheckBoxIndex.includes(index.toString())) {
        setValue(`account_contact.${index}.can_upload`, true);
      } else {
        setValue(`account_contact.${index}.can_upload`, false);
      }
    });
    scrollToBottom();
  }, [selectedCanUploadCheckBoxIndex]);

  useEffect(() => {
    if (accountData && accountData.length) {
      reset({ account_contact: accountData });
    }
    let primaryContacts =
      accountData &&
      accountData
        .map((a, i) => (a.is_primary ? i.toString() : null))
        .filter(index => index !== null);
    primaryContacts && spacbiState([...primaryContacts]);

    let canUploadContacts =
      accountData &&
      accountData
        .map((a, i) => (a.can_upload ? i.toString() : null))
        .filter(index => index !== null);
    canUploadContacts && setSelectedCanUploadCheckBoxIndex([...canUploadContacts]);
  }, [accountData]);

  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <VStack display={'block'} >
          <SimpleGrid
            columns={{ sm: 1, md: 4 }}
            spacing={GRID_SPACING}
            alignItems={'baseline'}
          >
            <FormTextInput
              name={`account_contact.${index}.first_name`}
              control={control}
              placeholder={'First Name'}
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.last_name`}
              control={control}
              placeholder={'Last Name'}
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.email`}
              control={control}
              placeholder={'Email'}
              size="md"
            />

            {index.toString() !== radioValue ? (
              <Text
                onClick={() => contactField.remove(index)}
                cursor={'pointer'}
                color={AppColors.secondary}
                fontSize="sm"
              >
                Remove
              </Text>
            ) : null}
          </SimpleGrid>
          <Box display={'flex'} alignItems={'center'} gap={'2'}>
            <Checkbox
              onChange={e => handleCheckBox(e, index)}
              value={index.toString()}
              defaultChecked={selectedPrimaryAccountCheckBoxIndex.includes(index.toString())}
            >Primary Account</Checkbox>
            <Checkbox
              onChange={e => handleCheckBoxForCanUpload(e, index)}
              value={index.toString()}
              defaultChecked={selectedCanUploadCheckBoxIndex.includes(index.toString())}
              ml={3}
            >Can Upload</Checkbox>
          </Box>
        </VStack>
      </React.Fragment>
    );
  }

  function renderAddIcon(_props) {
    return (
      <HStack mt={3} mr={12} mb={5} _hover={{ cursor: 'pointer' }} {..._props}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <AddIcon style={{ width: 18, height: 18 }} />
      </HStack>
    );
  }

  function renderContacts() {
    return (
      <SimpleGrid
        spacing={GRID_SPACING}
      >
        {contactField.fields.map(renderAddContactForm)}
      </SimpleGrid>
    );
  }
  return (
    <Modal size={'4xl'} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay style={{ backgroundColor: '#001A41' }} />
      <ModalContent mx={4} px={8}>
        <ModalHeader fontFamily={FONT_FAMILY.baiJamurjee} textAlign={'center'}>
          Edit Account Contact
        </ModalHeader>
        <Box
          style={{
            zIndex: '1',
          }}
          position={'absolute'}
          top={-10}
          right={-3}
        >
          <Circle>
            <ModalCloseButton
              size={'sm'}
              style={{ color: AppColors.white }}
              backgroundColor={'#8894A6'}
              p={3}
              onClick={onClose}
              borderRadius={50}
            />
          </Circle>
        </Box>
        <ModalBody p={6}>
          <FormControl>
            <Box>
              <Box
                maxHeight={270}
                overflow="scroll"
                overflowX={'hidden'}
                ref={scrollContainerRef}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 4px lightGrey',
                    width: '4px',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'grey',
                    borderRadius: '4px',
                  },
                }}
              >
                {renderContacts()}
              </Box>
              <Box>
                {renderAddIcon({
                  onClick: () => {
                    contactField.append(createNewContact());
                    setSelectedCanUploadCheckBoxIndex(prev => [...prev, contactField.fields.length.toString()]);
                  },
                })}
              </Box>
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter justifyContent={'center'} pt={0}>
          <Button
            mr={3}
            borderRadius={'full'}
            px={12}
            outline={`2px solid ${AppColors.secondary}`}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.secondary,
              color: AppColors.white,
            }}
            onClick={onClose}
            variant={'outline'}
          >
            <Text fontWeight={'normal'} fontSize={'sm'}>
              Cancel
            </Text>
          </Button>
          <Button
            bgColor={AppColors.primary}
            borderRadius={'full'}
            px={12}
            outline={`2px solid ${AppColors.primary}`}
            color={AppColors.white}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.white,
              color: AppColors.primary,
            }}
            onClick={handleSubmit(submitAddAccount)}
          >
            <Text fontSize={'sm'}>Update</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export { EditAccountContact };
